import {
	datadogRum,
	RumGlobal,
	RumInitConfiguration,
} from '@datadog/browser-rum';
import { getAudience } from './audience';

const datadogConfig: RumInitConfiguration = {
	applicationId: '213c3eb5-7e09-4800-b35d-8c668d69a306',
	clientToken: 'pub3df4eb23494c49d2b41ee66d18fb857c',
	site: 'datadoghq.com',
	service: 'fe-main',
	enableExperimentalFeatures: ['clickmap', 'feature_flags'],
	env: window.ENV,
	sessionSampleRate: 10,
	sessionReplaySampleRate: 100,
	trackResources: true,
	trackLongTasks: true,
	defaultPrivacyLevel: window.ENV === 'stage' ? 'mask-user-input' : 'mask',
	version: `${ window.BUILD_VERSION }`,
};

const addFeatureFlags = (datadogRum: RumGlobal) => {
	try {
		datadogRum.addFeatureFlagEvaluation('encore', window.ENCORE_ENABLED);
		datadogRum.addFeatureFlagEvaluation('audience', getAudience());
	} catch (e) {
		if (window.ENV === 'dev') {
			throw Error(`Error adding feature flags to datadog: ${ e }`);
		}
		console.error('Error adding feature flags to datadog: ', e);
	}
};

if (window.SESSION_USER && (window.ENV === 'stage' || window.ENV === 'prod')) {
	datadogRum.init(datadogConfig);
	addFeatureFlags(datadogRum);
	datadogRum.startSessionReplayRecording();
}
