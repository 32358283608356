import jQuery from 'jquery';

Object.defineProperties(window, {
	jQuery: {
		get: () => jQuery,
		set: () => null,
	},
	$: {
		get: () => jQuery,
		set: () => null,
	},
});

import 'jquery-ui/ui/jquery.ui.core';
import 'jquery-ui/ui/jquery.ui.widget';
import 'jquery-ui/ui/jquery.ui.mouse';
import 'jquery-ui/ui/jquery.ui.position';
import 'jquery-ui/ui/jquery.ui.draggable';
import 'jquery-ui/ui/jquery.ui.droppable';
import 'jquery-ui/ui/jquery.ui.resizable';
import 'jquery-ui/ui/jquery.ui.sortable';
import 'jquery-ui/ui/jquery.ui.accordion';
import 'jquery-ui/ui/jquery.ui.button';
import 'jquery-ui/ui/jquery.ui.datepicker';
import 'jquery-ui/ui/jquery.ui.dialog';
import 'jquery-ui/ui/jquery.ui.effect';
import 'jquery-ui/ui/jquery.ui.effect-highlight';
import 'jquery-ui/ui/jquery.ui.effect-slide';
import 'jquery-ui/ui/jquery.ui.effect-transfer';
import 'jquery-migrate';

if (window.BambooHR.env.prod) {
	jQuery.migrateMute = true;
}

export default jQuery;
