import i18next from 'i18next.lib';
import { canUseLocalStorage } from '@utils/localstorage';

const NAMESPACE = 'translation';
export const BHR_LS_I18N_PREFIX = 'bhr_i18n_';

export const getI18n = (language) => {
	i18next.init({
		lng: language,
		fallbackLng: false,
		defaultNS: NAMESPACE,
		nsSeparator: '█',
		keySeparator: false,
		preload: [language],
		initImmediate: false,
		ns: [
			NAMESPACE,
			'moment'
		],
		load: 'currentOnly',
		backend: {
			allowMultiLoading: true,
			loadPath: '/js/locale/i18n.php?lang={{lng}}&ns={{ns}}'
		},
		cache: {
			enabled: canUseLocalStorage(),
			prefix: BHR_LS_I18N_PREFIX
		}
	});

	return i18next;
};
