import {
	attributeNamesFromSettings,
} from '../util';
import {
	SETTINGS,
} from './constants';

export class BaOption {
	/**
	 * Returns an array of attribute names that this custom element supports
	 * @return {object} Array of attribute names
	 */
	static getCustomAttributes() {
		return attributeNamesFromSettings({
			...SETTINGS,
			hidden: true,
		});
	}

	constructor(element) {
		this._element = element;
		this._optionChangeEvent = new CustomEvent('ba:optionAttributeChange', {
			bubbles: true
		});
	}

	connectedCallback() {
		if (!this._element.hasOwnProperty('selected')) {
			Object.defineProperty(
				this._element,
				'selected',
				{
					get: () => this._element.hasAttribute('selected'),
					set: (value) => {
						if (value) {
							this._element.setAttribute('selected', '');
						} else {
							this._element.removeAttribute('selected');
						}
					},
				}
			);
		}

		if (!this._element.hasOwnProperty('value')) {
			Object.defineProperty(
				this._element,
				'value',
				{
					get: () => this._element.getAttribute('value'),
					set: (value) => {
						this._element.setAttribute('value', value);
					},
				}
			);
		}
	}

	attributeChangedCallback(attributeName, oldAttributeValue, newAttributeValue) {
		const attribute = document.createAttribute(attributeName);
		attribute.value = newAttributeValue;

		// IE 11 Workaround - "disabled" attribute doesn't trigger callback when removed
		if (attributeName === 'disabled' && newAttributeValue === '') {
			this._element.setAttribute('disabled', 'disabled');
		}

		if (this._optionChangeEvent) {
			this._element.dispatchEvent(this._optionChangeEvent);
		}
	}
}
