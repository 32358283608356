import BaDropdown from './ba-dropdown';
import BaIcon from './ba-icon';
import BaOption from './ba-option';
import BaSelect from './ba-select';
import BaUpload from './ba-upload';
import {
	ENCORE_ICON_COLOR_ATTRIBUTE,
	ENCORE_ICON_NAME_ATTRIBUTE,
	ENCORE_ICON_SIZE_ATTRIBUTE,
	ICON_BRAND_ATTRIBUTE,
	ICON_NAME_ATTRIBUTE
} from './constants';
import Manager from './manager';
import './ba-file-icon';

const elements = {
	'ba-dropdown': {
		attributes: BaDropdown.getCustomAttributes(),
		construct: BaDropdown,
	},
	'ba-icon': {
		attributes: [
			ICON_NAME_ATTRIBUTE,
			ICON_BRAND_ATTRIBUTE,
			ENCORE_ICON_NAME_ATTRIBUTE,
			ENCORE_ICON_COLOR_ATTRIBUTE,
			ENCORE_ICON_SIZE_ATTRIBUTE,
			// NOTE: artboard attrs are deprecated
			'artboard-align',
			'artboard-size',
		],
		construct: BaIcon,
	},
	'ba-option': {
		attributes: BaOption.getCustomAttributes(),
		construct: BaOption,
	},
	'ba-select': {
		attributes: BaSelect.getCustomAttributes(),
		construct: BaSelect,
	},
	'ba-upload': {
		attributes: BaUpload.getCustomAttributes(),
		construct: BaUpload,
	},
};

Manager.register(elements);
