import {
	kebabCase,
	mergeWith,
	omitBy,
} from 'lodash';

/**
 * Deeply merges multiple objects together but instead of overwriting nested arrays
 * when there is a collision, it will concat the items contained in arrays
 * @param  {Object} objects Objects to merge
 * @return {Object}         The merged object
 */
export const mergeDeep = (...objects) => {
	return mergeWith(
		{},
		...objects,
		(objValue, srcValue) => {
			if (Array.isArray(objValue)) {
				return objValue.concat(srcValue);
			}
		}
	);
};

/**
 * Converts an Object's keys to be kebab case
 * @param  {object} obj The object to convert
 * @return {object}     The converted objects
 */
export const toKebabKeys = (obj) => {
	return Object.keys(obj)
		.reduce((memo, key) => {
			memo[kebabCase(key)] = obj[key];
			return memo;
		}, {});
};

/**
 * Returns a new object with any falsy properties removed
 * @param {object} obj The object to remove false values from
 * @return {object}    An object with false properties removed
 */
export function removeFalsyProperties(obj) {
	return omitBy(obj, value => !value);
}
