import {
	queryStringToObject,
} from 'BambooHR.util';
import {
	each,
	extend,
} from 'lodash';

const blacklist = [
	'CSRFToken',
];

const defaultHeaders = {
	'X-CSRF-TOKEN': window.CSRF_TOKEN,
};

$.ajaxPrefilter((opts, originalOpts, xhr) => {
	if (opts.type.toLowerCase() === 'get') {
		// create an object from any query string params
		const params = opts.url.indexOf('?') >= 0 ? queryStringToObject(opts.url) : {};

		// use $.param to serialize objects first so we can make sure we support
		// everything that jQuery supports
		if (typeof originalOpts.data !== 'string') {
			originalOpts.data = $.param(originalOpts.data || {});
		}

		// make sure the data option is an object
		const data = queryStringToObject(originalOpts.data || '');

		// combine the data obj and query string params
		extend(params, data);

		// remove the query string from the url, since it's in the params obj now
		opts.url = opts.url.split('?')[0];

		// strip blacklist items from params
		blacklist.forEach(key => delete params[key]);

		// set data to new query string
		opts.data = $.param(params);

		// add default headers
		each(defaultHeaders, (val, key) => xhr.setRequestHeader(key, val));
	}
});
