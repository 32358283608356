import {
	SETTINGS as DROPDOWN_SETTINGS,
} from '../ba-dropdown/constants';

export const SETTINGS = {
	...DROPDOWN_SETTINGS,
	error: false,
	grow: false,
	multiselect: false,
	name: '',
	noResultsText: 'No results match.',
	notClearable: false,
	placeholder: '-- Select --',
	required: false,
	searchThreshold: 8,
	selectionFormat: 'text',
	showSearch: 'auto',
	theme: 'ba-Select',
	toggleClass: ''
};
