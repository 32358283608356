import {
	isBoolean,
	noop
} from 'lodash';

const DISABLED_BTN_EVENTS = [
	'click'
];

$.fn.toggleDisabled = function(state) {
	return this.each(function() {
		var $this = $(this),
			disabled = state;

		if (!isBoolean(state)) {
			disabled = !$this.hasClass('disabled') && !$this.prop('disabled');
		}

		$this
			.toggleClass('disabled', disabled)
			.prop('disabled', disabled);

		if (disabled) {
			$this.attr('disabled', 'disabled');
		} else {
			$this.removeAttr('disabled');
		}
	});
};

$.fn.disable = function() {
	return $(this).toggleDisabled(true);
};

$.fn.enable = function() {
	return $(this).toggleDisabled(false);
};

$.fn.toggleProcessing = function(state) {
	return $(this).each(function() {
		var $this = $(this),
			processing = state;

		if (!isBoolean(state)) {
			processing = !$this.hasClass('processing');
		}

		$this
			.toggleClass('processing', processing)
			.toggleDisabled(processing);
	});
};


DISABLED_BTN_EVENTS.forEach((event) => {
	$.event.special[event] = $.event.special[event] || {};

	let _handle = $.event.special[event].handle;

	$.event.special[event].handle = function handle(e) {
		let $this = $(e.target).closest('.btn, a');
		let handler = e.handleObj.handler;


		switch (event) {
			case 'click':
				if ($this.hasClass('btn--single-click')) {
					$this.disable();
				}
				break;
		}

		if (
			// it isn't a button...
			$this.length < 1 ||

			// ...or it's not disabled
			(
				!$this.hasClass('processing') &&
				!$this.hasClass('disabled') &&
				!$this.prop('disabled')
			)
		) {
			return handler.apply(this, arguments);
		}

		(_handle || noop).apply(this, arguments);
	}
});
