import { isPlainObject } from 'lodash';

export const get__n = (i18next, coercePlural, logTrans) => {
	return (str, plural, ...args) => {
		if (args.length && isPlainObject(args[args.length - 1])) {
			args.pop();
		}

		let trans;
		try {
			trans = i18next.t(coercePlural(str, plural, args[0]), {
				count: args[0],
				postProcess: 'sprintf',
				sprintf: args,
			});
		} catch (e) {
			window.Rollbar.error(`Error translating plural string for lang:${i18next.language}`, args, str, plural);
			console.error(`Error translating plural string for lang:${i18next.language}`, args, str, plural);
		}

		logTrans('__n', str, trans);
		return trans;
	};
};
