
import { Dropdown as ReactDropdown } from '@fabric/dropdown';
import { unmountComponentAtNode } from 'react-dom';
import { render } from 'base/wrapped-render';
import { makeUid } from 'BambooHR.util';
import {
	canUseLocalStorage,
} from '@utils/localstorage';
import {
	SETTINGS,
} from './constants';
import {
	attributeNamesFromSettings,
	convertToList,
	fromElementToItemMap,
	elementsNameMap
} from '../util';
import MutationSummary from 'mutation-summary';

export class BaDropdown {
	constructor(element) {
		this._element = element;
		this._reactRoot = document.createElement('div');
		this.attributes = {};

		this._assignKeys();
		this._createItemList();
		this._element.addEventListener('ba:optionAttributeChange', () => {
			this._createItemList();
			this._renderReactDropdown();
		});
	}
	_createItemList() {
		this._list = convertToList(this._element.childNodes).filter(item => item);
	}
	_assignKeys(parent = this._element) {
		if (parent.childElementCount) {
			const { group, option } = elementsNameMap;

			[...parent.childNodes].filter(node => (
				node.nodeName && (node.nodeName === option || node.nodeName === group)
			)).forEach((node) => {
				if (!node.attributes.key) {
					node.setAttribute('key', makeUid());
				}
				if (node.childElementCount) {
					this._assignKeys(node);
				}
			});
		}
	}
	_renderReactDropdown() {
		const { attributes } = this;
		const dropdownProps = fromElementToItemMap({ attributes });

		render(
			<ReactDropdown
				ButtonProps={ dropdownProps.buttonSettings }
				items={ this._list }
				onClose={ () => {
					this._element.dispatchEvent(
						new CustomEvent('ba:dropdownClose', {
							bubbles: true
						})
					);
				} }
				onOpen={ () => {
					this._element.dispatchEvent(
						new CustomEvent('ba:dropdownOpen', {
							bubbles: true
						})
					);
				} }
				onSelect={ (value, item) => {
					this._element.dispatchEvent(
						new CustomEvent('ba:dropdownSelect', {
							bubbles: true,
							detail: {
								item,
								value
							}
						})
					);
					if (dropdownProps.stayOpen) {
						return new Promise(() => {});
					}
				} }
				renderOptionContent={ item => (item.render ? item.render() : item.text) }
				{ ...dropdownProps }
			/>,
			this._reactRoot
		);
	}
	_setUpMutationObserver() {
		// Wait until initial DOM mutations have completed
		setTimeout(
			() => {
				this._update();

				this._mutationObserver = new MutationSummary(
					{
						callback: (summaries) => {
							this._update();
						},
						queries: [
							{characterData: true},
							{element: 'ba-option'}
						],
						rootNode: this._element
					}
				);
			}
		);
	}

	_update() {
		this._assignKeys();
		this._createItemList();
		this._renderReactDropdown();
	}

	connectedCallback() {
		this.attributes = Array.from(this._element.attributes);
		this._element.appendChild(this._reactRoot);
		this._renderReactDropdown();
		this._setUpMutationObserver();
	}

	disconnectedCallback() {
		if (this._mutationObserver?.connected) {
			this._mutationObserver.disconnect();
		}
		unmountComponentAtNode(this._reactRoot);
	}

	attributeChangedCallback(attributeName, oldAttributeValue, newAttributeValue) {
		// IE 11 Workaround - "disabled" attribute doesn't trigger callback when removed
		if (attributeName === 'disabled' && newAttributeValue === '') {
			this._element.setAttribute('disabled', 'disabled');
		}

		this.attributes = Array.from(this._element.attributes);
		this._renderReactDropdown();
	}

	/**
	 * Returns an array of attribute names that this custom element supports
	 * @return {object} Array of attribute names
	 */
	static getCustomAttributes() {
		const settings = {
			...SETTINGS,
			'button-outline': false,
			'button-text': '',
			'button-icon': '',
			'button-iconBefore': '',
			'button-iconAfter': '',
			'button-dark': false,
			'button-secondary': false,
			'placement-align': '',
			'placement-side': ''
		};

		return attributeNamesFromSettings(settings);
	}
}
