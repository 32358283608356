import MutationSummary from 'mutation-summary';

const REGISTERED_ELEMENTS = {};

let mutationSummary = null;

/**
 * Handler that is called when a DOM mutation occurs
 * @param  {object} summaries Mutation Summary summary object
 */
function mutationHandler(summaries) {
	summaries.forEach((summary) => {
		summary.added.forEach(function(element) {
			let tagName = element.tagName.toLowerCase();
			let customElement;
			if (element.__ba) {
				customElement = element.__ba;
			} else {
				customElement = new REGISTERED_ELEMENTS[tagName].construct(element);
				element.__ba = customElement;
			}

			customElement.connectedCallback();
		});

		summary.removed.forEach((element) => {
			if (element.__ba && typeof element.__ba.disconnectedCallback === 'function') {
				element.__ba.disconnectedCallback();
			}
		});

		if (summary.attributeChanged) {
			Object.keys(summary.attributeChanged).forEach((attributeName) => {
				summary.attributeChanged[attributeName].forEach((element) => {
					let oldAttributeValue = summary.getOldAttribute(element, attributeName);
					let newAttributeValue = element.getAttribute(attributeName);

					if (element.__ba) {
						element.__ba.attributeChangedCallback(attributeName, oldAttributeValue, newAttributeValue);
					}
				});
			});
		}
	});
}

/**
 * Converts an element definition to a Mutation Summary query
 * @param  {object} elements Element definition
 * @return {object}          Array of element queries
 */
function elementsToQueries(elements) {
	return Object
		.keys(elements)
		.map((name) => {
			var attributes = elements[name].attributes || '';
			let query = {element: name};

			if (attributes.length) {
				query.elementAttributes = attributes.join(' ');
			}

			return query;
		});
}

export default {
	/**
	 * Registers a class to be updated when changes are made to the attached
	 * DOM element.
	 * @param  {object} elements Map of element definitions to their tag name
	 */
	register(elements) {
		Object.assign(REGISTERED_ELEMENTS, elements);

		if (mutationSummary !== null) {
			mutationSummary.disconnect();
		}

		mutationSummary = new MutationSummary({
			callback: mutationHandler,
			observeOwnChanges: true,
			queries: elementsToQueries(elements)
		});
	}
};
