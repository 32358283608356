let resManifest = window.__resManifest;
delete window.__resManifest;

const _url = new WeakMap();
const _cb = new WeakMap();

function makeRandomHash(len = 8) {
	return Math.random().toString(36)
		.replace(/[^a-z0-9]+/gi, '')
		.substr(-(len));
}

function extractCb(url) {
	let cb = url.searchParams.get('cb');

	if (!cb) {
		cb = url.pathname.replace(/^.+\.([a-z0-9]{8})\.dist\.[^\.]+$/, '$1');
		cb = (cb === url.pathname) ? null : cb;
	}

	if (!cb && window.BUILD_VERSION) {
		const m = (/-([a-z0-9]+)$/).exec(window.BUILD_VERSION);
		if (m && typeof m[1] === 'string') {
			cb = ('00000000' + m[1]).substr(-8);
		}
	}

	return cb || makeRandomHash();
}

class Res {
	static get STATIC_RESOURCES_CDN() {
		return window.DOMAIN;
	}

	static get BASE_DOMAIN() {
		return window.BASE_DOMAIN;
	}

	static get I18N_VERSION() {
		return resManifest['i18n_strings.json'];
	}

	static set manifest(val) {
		if (
			!resManifest &&
			typeof val === 'object'
		) {
			resManifest = val;
		}
	}

	static getCacheBustValue(path) {
		return new Res(path).cb;
	}

	static get(path, params, includeDomain) {
		if (
			arguments.length === 2 &&
			typeof params === 'boolean'
		) {
			includeDomain = params;
			params = {};
		}

		return (new Res(path, params)).toString(includeDomain);
	}

	constructor(pathStr = '', params = {}) {
		if (typeof pathStr !== 'string') {
			throw new TypeError('Res constructor expects a string as first argument');
		}

		var url = new window.URL(pathStr, window.ROOT_URL);

		pathStr = url.pathname.replace(/^\/+/, '');

		var hashedPath = resManifest[pathStr];
		var cb = '';

		if (hashedPath) {
			if (hashedPath.match(/^[a-z0-9]{8}$/)) {
				// This is a hash, not a path
				cb = hashedPath;
				url.searchParams.set('cb', cb);
			} else {
				url = new window.URL(hashedPath, window.ROOT_URL);
				cb = extractCb(url);
			}
		} else if (pathStr !== '') {
			cb = extractCb(url);
			url.searchParams.set('cb', cb);
			resManifest[pathStr] = `${ pathStr }?cb=${ cb }`;
		}

		_cb.set(this, cb);

		Object.keys(params || {}).forEach((k) => {
			if (k === 'cb') {
				return;
			}

			url.searchParams.set(k, params[k]);
		});

		if (
			window.BUILD_VERSION !== '' &&
			!(/\.[a-z0-9]{8}\.dist\./).test(url.pathname) &&
			url.pathname !== '/'
		) {
			let pathPrefix = window.BUILD_VERSION
			if (url.pathname.indexOf('/images') === 0 && window.STATICFE_UI_SRC) {
				pathPrefix = window.IMAGE_RESOURCE;
			}
			url.pathname = `/${ pathPrefix }/${ url.pathname.replace(/^\//, '') }`;
		}

		_url.set(this, url);
	}

	get domain() {
		return _url.get(this).hostname;
	}

	get path() {
		return _url.get(this).pathname;
	}

	get qs() {
		return _url.get(this).search;
	}

	get hash() {
		return _url.get(this).hash.replace(/^\#/, '');
	}

	get cb() {
		return _cb.get(this);
	}

	toString(includeDomain = true) {
		const {
			pathname,
			href,
			origin,
		} = _url.get(this);
		let pathStr = href
			.replace(/\/+$/, '');

		if (pathname.endsWith('.svg')) {
			// svgs must be loaded from the current
			// domain to avoid cross-domain issues
			includeDomain = false;
		}

		if (!window.DOMAIN || !includeDomain) {
			pathStr = href.replace(origin, '')
				.replace(/\/+$/, '');
		}

		return pathStr;
	}
}

export default Res;
window.Res = Res;
window.res = (...args) => {
	console.warn('Use of window.res() is deprecated. Please use the window.Res.get() global or import Res directly.');
	return Res.get(...args);
};
