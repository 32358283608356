import i18next from 'i18next.lib';
import moment from 'moment.lib';
import { appendMomentScript } from './append-moment-script';

const MOMENT_LOCALE_OVERRIDE = {
	'fr-ca': {
		monthsShort: ['jan', 'fév', 'mar', 'avr', 'mai', 'jun', 'jui', 'aoû', 'sep', 'oct', 'nov', 'déc'],
		weekdaysShort: ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam']
	},
	'de': {
		monthsShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
		weekdaysShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
	},
	'pt-br': { // pt_BR
		monthsShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
		weekdaysShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb']
	},
	'nl': { // nl_NL
		monthsShort: ['jan', 'feb', 'mrt', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
		weekdaysShort: ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za']
	},
	'es': { // es_AR
		monthsShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
		weekdaysShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb']
	},
	'zh-cn': { // zh_CN
		monthsShort: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
		weekdaysShort: ['日', '一', '二', '三', '四', '五', '六']
	},
	'ja': { // ja_JP
		monthsShort: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
		weekdaysShort: ['日', '月', '火', '水', '木', '金', '土']
	}
};

export const configureMomentOnLanguageChange = () => {
	i18next
		.on('languageChanged', (lang) => {
			const localeCode = i18next.t('moment█locale'),
				id = `moment_locale_${ lang }`,
				fullMomentLang = lang.toLowerCase().replace(/[^a-z]+/gi, '-'),
				shortMomentLang = fullMomentLang.split('-').shift(),
				locales = moment.locales();

			if (locales.includes(fullMomentLang)) {
				moment.locale(fullMomentLang);
			} else if (locales.includes(shortMomentLang)) {
				moment.locale(shortMomentLang);
			} else if (
				localeCode !== '' &&
				!document.getElementById(id)
			) {
				appendMomentScript($,
					`
<script
	id="${ id }"
	type="text/javascript">
	${ localeCode }
</script>`
				);
			}

			moment.updateLocale(moment.locale(), MOMENT_LOCALE_OVERRIDE[moment.locale()] || {});

			$.datepicker.setDefaults({
				dayNames: moment.weekdays(),
				dayNamesShort: moment.weekdaysShort(),
				dayNamesMin: moment.weekdaysMin(),
				monthNames: moment.months(),
				monthNamesShort: moment.monthsShort()
			});
		});
};
