import { isPlainObject } from 'lodash';

export const get__ = (i18next, logTrans) => (str, ...args) => {
	if (args.length && isPlainObject(args[args.length - 1])) {
		args.pop();
	}

	let trans;
	try {
		trans = i18next.t(str, {
			postProcess: 'sprintf',
			sprintf: args,
		});
	} catch (e) {
		window.Rollbar.error(`Error translating singular string for lang:${i18next.language}`, args, str);
		console.error(`Error translating singular string for lang:${i18next.language}`, args, str);
	}

	logTrans('__', str, trans);
	return trans;
};
