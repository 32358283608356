import {
	FileUpload,
} from '@fabric/file-upload';
import {
	camelCase,
} from 'lodash';
import { render } from 'base/wrapped-render';

import {
	settingsFromAttributes,
} from '../util';


export default class BaUpload {
	static getCustomAttributes() {
		return [
			'accepted-types',
			'can-select-multiple',
			'has-custom-error-handler',
			'initial-files',
			'name',
			'is-required',
		];
	}

	get props() {
		return settingsFromAttributes(this._element.attributes, {
			acceptedTypes: 'json',
			canSelectMultiple: 'boolean',
			hasCustomErrorHandler: 'boolean',
			initialFiles: 'json',
			name: 'string',
			isRequired: 'boolean',
		});
	}

	get reactRoot() {
		let root = this._element.querySelector('.js-react-upload-root');

		if (!root) {
			root = document.createElement('div');
			root.classList.add('js-react-upload-root');
			this._element.appendChild(root);
		}

		return root;
	}

	constructor(element) {
		this._element = element;
	}

	connectedCallback() {
		this._renderFileUpload();
	}

	attributeChangedCallback(attr, oldValue, newValue) {
		this._renderFileUpload();
	}

	_renderFileUpload() {
		render(
			<FileUpload
				{ ...this.props }
				onChange={ (files, promises) => this._dispatchEvent('change', {
					files,
					promises,
				}) }
				onError={ error => this._dispatchEvent('error', error) }
				onUpload={ data => this._dispatchEvent('upload', data) }
			/>,
			this._element
		);
	}

	_dispatchEvent(name, detail = {}) {
		this._element.dispatchEvent(new CustomEvent(`ba:${ camelCase(['fileUpload', name]) }`, {
			detail,
		}));
	}
}
