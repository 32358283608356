export const releaseMap = {
	Development: '4',
	Alpha: '3',
	'Early Access': '2',
	'General Access': '1',
};

export const getAudience = () => {
	const releaseId = window.SESSION_COMPANY?.releaseId;
	return (
		Object.entries(releaseMap).find(([, id]) => id === releaseId)?.[0] ||
		'default'
	);
};
