import moment from 'moment.lib';
// eslint-disable-next-line no-restricted-imports
import jQuery from 'jquery.lib';
import { initialize, cleanStaleVersions } from './i18n';

window.jQuery = window.jQuery || jQuery;
window.moment = window.moment || moment;

cleanStaleVersions();
initialize();
