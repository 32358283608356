var plugins = [];
$.registerElementInitializer = function registerElementInitializer(selector, attacher, options) {
	plugins.push({selector: selector, attachFunc: attacher, options: options});
	return this;
};

$.fn.initializeElements = function initializeElements() {
	var $this = this;
	return $.each(plugins, function(index, item) {
		item.attachFunc.apply($(item.selector, $this), [$this, item.options]);
	});
};

var observations = {},
	MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;

function observe() {
	var $target = $(this);
	$.each(observations, function(selector, funcs) {
		$target.find(selector).each(function() {
			var elem = this;
			funcs.forEach(function(fn) {
				fn.call(elem);
			});
		});
	});
}

$(() => {
	$.each(observations, function(selector, funcs) {
		$(selector).each(function() {
			var elem = this;
			funcs.forEach(function(fn) {
				fn.call(elem);
			})
		})
	})
});

if (MutationObserver) {
	var observer = new MutationObserver(function(mutations) {
		mutations.forEach(function(mutation) {
			$(mutation.addedNodes).each(observe);
		});
	});

	$(() => {
		observer.observe(document.body, {
			childList: true,
			subtree: true
		});
	});
} else if ('MutationEvent' in window) {
	$(document).on('DOMNodeInserted', function(e) {
		observe.call(e.target);
	});
}

$.fn.observe = function observe(fn) {
	if (!$.isArray(observations[this.selector])) {
		observations[this.selector] = [];
	}

	observations[this.selector].push(fn);
};

$.fn.destroyDatepicker = function destroyDatepicker() {
	this.each(function(idx,itm) {
		$(this)
			.removeClass('hasDatepicker')
			.removeAttr("id")
			.removeData("datepicker")
			.unbind();
	});

	return this;
};
