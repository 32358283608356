export const getMomentConfigEn = (moment) => {
	// Courtesy of Lance Miller
	const relativeTranslations = {
		_JUST_NOW_: $.__('Just now'),
		_TOMORROW_: $.__('Tomorrow'),
		_YESTERDAY_: $.__('Yesterday'),
	};

	const { _relativeTime } = moment.localeData();
	const pastFuture = (tense, str) => {
		return relativeTranslations[str] || _relativeTime[tense].replace(/%s/i, str);
	};

	return {
		relativeTime: {
			future: str => pastFuture('future', str),
			past: str => pastFuture('past', str),
			s: (num, noSuffix) => {
				return noSuffix ? relativeTranslations._JUST_NOW_ : '_JUST_NOW_';
			},
			m: $.__('1 minute'),
			h: $.__('1 hour'),
			d: (num, noSuffix, key, isFuture) => {
				const translationKey = isFuture ? '_TOMORROW_' : '_YESTERDAY_';
				return noSuffix ? relativeTranslations[translationKey] : translationKey;
			},
			M: $.__('1 month'),
			y: $.__('1 year'),
		},
	};
};
