import { getCurrentLanguage } from './get-current-language';
import { getI18n } from './get_i18n';
import { configureDateFnsForEnglish } from './configure-date-fns-for-english';
import { configureDateFnsOnLanguageChange } from './configure-date-fns-on-language-change';
import { configureMomentForEnglish } from './configure-moment-for-english';
import { configureMomentOnLanguageChange } from './configure-moment-on-language-change';
import { configureT } from './configure-t';

export const initialize = () => {
	const language = getCurrentLanguage();

	configureMomentOnLanguageChange();
	configureDateFnsOnLanguageChange();

	const i18n = getI18n(language);
	configureT(i18n, language);
	configureMomentForEnglish(i18n);
	configureDateFnsForEnglish(i18n);

	return i18n;
};
