import {
	FileIcon,
	type IconSize,
} from '@bamboohr/fabric';

import {
	render,
} from 'base/wrapped-render';

/** The attribute name for the esig attribute */
const ATTRIBUTE_ESIG = 'esig';
/** The attribute name for the name attribute */
const ATTRIBUTE_NAME = 'name';
/** The attribute name for the size attribute */
const ATTRIBUTE_SIZE = 'size';

/**
 * The valid sizes for the file icon from Fabric
 */
const fileIconSizes: Array<IconSize> = ['teenie', 'small', 'medium', 'large'];

export class BaFileIcon extends HTMLElement {
	/**
	 * The element that the custom element is attached to
	 */
	_element: HTMLElement;

	/**
	 * Constructor
	 */
	constructor() {
		super();
		this._element = this;
	}

	/**
	 * Returns the attributes that should be observed for changes
	 */
	static get observedAttributes() {
		return [ATTRIBUTE_ESIG, ATTRIBUTE_NAME, ATTRIBUTE_SIZE];
	}

	/**
	 * Lifecycle method called when the element is added to the DOM
	 */
	connectedCallback() {
		this._injectFileIcon();
	}

	/**
	 * Lifecycle method called when an attribute is change in the DOM
	 */
	attributeChangedCallback() {
		this._injectFileIcon();
	}

	/**
	 * Injects a Fabric FileIcon into the element
	 */
	_injectFileIcon() {
		const esig = this._element.getAttribute(ATTRIBUTE_ESIG) === 'true';
		const name = this._element.getAttribute(ATTRIBUTE_NAME);
		let size = this._element.getAttribute(ATTRIBUTE_SIZE) as IconSize;
		if (!size) {
			size = 'teenie';
		}
		if (!fileIconSizes.includes(size)) {
			size = 'teenie';
		}
		if (esig) {
			render(<FileIcon esig={ true } size={ size } type="esig" />, this._element);
			return;
		}
		render(<FileIcon filename={ name } size={ size } />, this._element);
	}
}

customElements.define('ba-file-icon', BaFileIcon);
