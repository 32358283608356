import {
	toKebabKeys,
} from 'Object.util';

/**
 * Determines if a single option or group is to be rendered
 * @param  {object} items                   The item applyNewData
 * @param  {function} refCallback           Callback used to associate items to mergeElementStyles
 * @param  {function} optionContentCallback Callback used to render the content of an options
 * @return {jsx}                            JSX
 */
export const renderItems = (items, refCallback, optionContentCallback) => {
	return items.map((item, index) => {
		if (item.type === 'group') {
			return renderGroup(item, index, refCallback, optionContentCallback);
		}
		return renderOption(item, index, refCallback, optionContentCallback);
	});
};

/**
 * Renders an option group
 * @param  {object} item                    The item data
 * @param  {number} index                   The index of the group in a collection
 * @param  {function} refCallback           Callback used to associate items to elements
 * @param  {function} optionContentCallback Callback used to render the content of an option
 * @return {object}                         JSX
 */
export const renderGroup = (item, index, refCallback, optionContentCallback) => {
	const attributes = {};

	if (item.displayText) {
		attributes.label = item.displayText;
	}

	if (item.anchor) {
		attributes.anchor = item.anchor;
	}

	return (
		<ba-option-group { ...attributes } key={ item.key || index }>
			{ item.items && renderItems(item.items, refCallback, optionContentCallback) }
		</ba-option-group>
	);
};

/**
 * Renders the option for the list
 * @param  {object} item                    The item being rendered into a single option
 * @param  {number} index                   The index of the item in a collection
 * @param  {function} refCallback           Callback used to associate items to elements
 * @param  {function} optionContentCallback Callback used to render the content of an option
 * @return {jsx}                            Element to render
 */
export const renderOption = (item, index, refCallback, optionContentCallback) => {
	const attributes = {};

	if (item.selected) {
		attributes.selected = 'true';
	}

	if (item.disabled) {
		attributes.disabled = 'true';
	}

	return (
		<ba-option 
			{ ...attributes } 
			key={ item.key || index } 
			ref={ element => refCallback(element, item) } 
			selection-text={ item.displayText }
			value={ item.value } 
		>
			{ renderOptionContent(item, optionContentCallback) }
			{ item.items && item.items.length > 0 && renderItems([{items: item.items, type: 'group'}], refCallback, optionContentCallback) }
		</ba-option>
	);
};

/**
 * Renders the specific type of option for the dropdown/select
 * @param  {object} item                    The item being rendered
 * @param  {function} optionContentCallback Callback used to render the content of an option
 * @return {object}                         JSX
 */
export const renderOptionContent = (item, optionContentCallback) => {
	if (optionContentCallback) {
		return optionContentCallback(item);
	}

	if (item.secondaryDisplayText) {
		return renderWithDescriptor(item);
	}

	if (item.icon) {
		return renderWithIcon(item);
	}

	return (
		<span>
			{ item.displayText }
		</span>
	);
};

/**
 * Renders Option with icon
 * @param  {object} Item to render
 * @return {jsx} Element to render
 */
export const renderWithIcon = (item, settings = {}) => {
	const { blockPrefix = 'ba-Dropdown' } = settings;
	let { displayText, icon } = item;

	icon = typeof icon === 'string' ? {name: icon} : icon;

	const artboardAttributes = icon.artboard ? toKebabKeys(icon.artboard) : [];
	const attributes = { ...artboardAttributes };

	if (icon.class) {
		attributes.class = `${ blockPrefix }__optionIcon ${ icon.class }`;
	} else {
		attributes.class = `${ blockPrefix }__optionIcon baseFillColor`;
	}

	return (
		<div className={ `${ blockPrefix }__option--icon` }>
			<ba-icon name={ icon.name } { ...attributes }></ba-icon>{ displayText }
		</div>
	);
};

/**
 * Renders Option with Descriptor Text
 * @param  {object} Item to render
 * @param  {settings} Settings for rendering the descriptpr
 * @return {jsx} Element to render
 */
export const renderWithDescriptor = (item, settings = {}) => {
	const {blockPrefix = 'ba-Dropdown'} = settings;
	return (
		<div className={ `${ blockPrefix }__optionDescriptor` }>
			{ item.displayText }
			<div className={ `${ blockPrefix }__optionDescriptorText` }>
				{ item.secondaryDisplayText }
			</div>
		</div>
	);
};
