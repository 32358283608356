import { getParameterByName } from '@utils/url';
import { get_ } from './t/underscore';
import { get__ } from './t/double-underscore';
import { get_n } from './t/underscore-n';
import { get__n } from './t/double-underscore-n';

export const configureT = (i18next, language) => {
	const log = getParameterByName('i18n_log');

	function logTrans(fn, ...strings) {
		if (log !== false && (log === 'all' || log === fn)) {
			console.log(...[`$.${ fn }`].concat(strings));
		}
	}

	const coercePlural = (str, plural, count) => {
		if (language.startsWith('en')) {
			return count === 1 ? str : plural;
		}
		return str;
	};

	const _ = get_(i18next);
	const _n = get_n(i18next, coercePlural);
	const __ = get__(i18next, logTrans);
	const __n = get__n(i18next, coercePlural, logTrans);
	const _temp_ = (featureName, ...args) => {
		if (typeof featureName !== 'string' || !args || typeof args[0] !== 'string') {
			throw new Error('Invalid arguments were supplied. The first argument should be the feature name. The second argument should be the text to be translated.');
		}
		return __(...args);
	};
	const _temp_n = (featureName, ...args) => {
		if (typeof featureName !== 'string' || !args || typeof args[0] !== 'string' || typeof args[1] !== 'string') {
			throw new Error('Invalid arguments were supplied. The first argument should be the feature name. The second and third arguments should be the singular and plural forms of the text to be translated.');
		}
		return __n(...args);
	};

	window.$.extend({
		_,
		_n,
		_temp_,
		_temp_n,
		__,
		__n,
	});
};
