import i18next from 'i18next.lib';
import { enUS, frCA, de, ptBR, nl, es, zhCN, ja, setDefaultOptions, Locale } from '@bamboohr/utils/lib/datetime';

const DATE_FNS_LOCALES: { [key: string]: Locale } = {
	'en': enUS,
	'en-US': enUS,
	'fr-CA': frCA,
	de,
	'pt-BR': ptBR,
	'nl-NL': nl,
	es,
	'zh-CN': zhCN,
	ja,
}

export const configureDateFnsOnLanguageChange = () => {
	i18next
		.on('languageChanged', (lang: string) => {
			setDefaultOptions({ locale: DATE_FNS_LOCALES[lang]});
		});
};