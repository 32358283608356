import {
	attributeNamesFromSettings,
	convertToList,
	elementsNameMap
} from '../util';

import SelectWrapper from './select-wrapper.react';
import { unmountComponentAtNode } from 'react-dom';
import { makeUid } from 'BambooHR.util';
import { render } from 'base/wrapped-render';
import MutationSummary from 'mutation-summary';

import {
	SETTINGS,
} from './constants';

document.addEventListener('ba:selectToggleUpdate', (e) => {
	const {selectedOptions, selectionFormat, toggleContentElement} = e.detail;
	if (selectedOptions.length > 1 && selectionFormat !== 'custom') {
		e.preventDefault();
		toggleContentElement.textContent = $.__('%1 Selected', selectedOptions.length);
	}
});

class BaSelect {
	/**
	 * Returns an array of attribute names that this custom element supports
	 * @return {object} Array of attribute names
	 */
	static getCustomAttributes() {
		const settings = {
			...SETTINGS,
			canAdd: false
		};
		return attributeNamesFromSettings(settings);
	}
	get reactRoot() {
		let root = this._element.querySelector('.js-react-select-root');
		if (!root) {
			root = document.createElement('div');
			root.classList.add('js-react-select-root');
		}
		return root;
	}

	constructor(element) {
		this._element = element;
		this.attributes = {};

		this._assignKeys();
		this._createItemList();
		this._originalList = [...this._list];
		this._element.addEventListener('ba:optionAttributeChange', () => {
			this._createItemList();
			this._renderReactSelect();
		});
	}
	resetSelect = () => {
		const _selectedValues = [];
		const setSelectedValues = (item) => {
			if (item.selected || item.isSelected) {
				_selectedValues.push(item.value);
			}
			if (item.items && item.items.length > 0) {
				item.items.forEach(setSelectedValues);
			}
		};

		this._originalList.forEach(setSelectedValues);
		this._originalList.forEach(item => this._toggleSelectedValue(item, _selectedValues));
	}
	_assignKeys(parent = this._element) {
		if (parent.childElementCount) {
			const { group, option } = elementsNameMap;

			[...parent.childNodes].filter(node => (
				node.nodeName && (node.nodeName === option || node.nodeName === group)
			)).forEach((node) => {
				if (!node.attributes.key) {
					node.setAttribute('key', makeUid());
				}
				if (node.childElementCount) {
					this._assignKeys(node);
				}
			});
		}
	}
	_createItemList() {
		this._list = convertToList(this._element.childNodes).filter(item => item);

		if (
			/**
			 * If not-clearable, default to first option
			 */
			this._element.hasAttribute('not-clearable') &&
			this._list.length &&
			!this._list.some(({ isSelected }) => isSelected)
		) {
			this._list[0].isSelected = true;
		}
	}
	_removeLegacyClasses() {
		[
			'ba-Select',
			'ba-LargeSelect',
			'ba-TextSelect',
			'ba-SmallSelect',
			'ba-TitleSelect',
			'ba-inlineSelect'
		].forEach(className => (this._element.classList.remove(className)));
	}
	_toggleSelectedValue(item, selectedValues) {
		const { _elem, value, items } = item;

		if (_elem) {
			if (selectedValues.includes(value)) {
				_elem.setAttribute('selected', true);
			} else if (_elem.attributes.selected) {
				_elem.removeAttribute('selected');
			}
		}
		if (items && items.length > 0) {
			items.forEach(item => this._toggleSelectedValue(item, selectedValues));
		}
	}
	_handleSelect = (selectedOptions, value, selectedValues) => {
		this._list.forEach(item => this._toggleSelectedValue(item, selectedValues));
		this._element.dispatchEvent(
			new CustomEvent('ba:selectChange', {
				bubbles: true,
				detail: {
					selectedOptions,
					value
				}
			})
		);
	}
	_renderReactSelect() {
		if (!this._element.contains(this.reactRoot)) {
			this._element.appendChild(this.reactRoot);
		}

		render(
			<SelectWrapper
				element={ this._element }
				items={ this._list }
				onSelect={ this._handleSelect }
			/>,
			this.reactRoot
		);
	}

	connectedCallback() {
		const attributes = Array.from(this._element.attributes);
		this.attributes = attributes;

		if (!this._element.contains(this.reactRoot)) {
			this._element.appendChild(this.reactRoot);
		}
		this._removeLegacyClasses();
		this._createItemList();
		this._renderReactSelect();

		if (!this._element.hasOwnProperty('values')) {
			Object.defineProperty(
				this._element,
				'values',
				{
					get: () => {
						const selectElement = this._element.querySelector('select');

						return [...selectElement.selectedOptions].filter((option) => {
							if (option.value !== '') {
								return option.value;
							}
						}).map(option => option.value);
					}
				}
			);
		}

		this._setUpMutationObserver();
	}

	disconnectedCallback() {
		unmountComponentAtNode(this.reactRoot);
		if (this._mutationObserver) {
			this._mutationObserver.disconnect();
		}
	}

	attributeChangedCallback(attributeName, oldAttributeValue, newAttributeValue) {
		// IE 11 Workaround - "disabled" attribute doesn't trigger callback when removed
		if (attributeName === 'disabled' && newAttributeValue === '') {
			this._element.setAttribute('disabled', 'disabled');
		}

		this.attributes = Array.from(this._element.attributes);
		this._removeLegacyClasses();
		this._createItemList();
		this._renderReactSelect();
	}

	/**
	 * Watches child option elements for changes so that we can update the toggle and hidden input values
	 */
	_setUpMutationObserver() {
		this._mutationObserver = new MutationSummary(
			{
				callback: () => {
					this._assignKeys();
					this._createItemList();
					this._renderReactSelect();
				},
				queries: [
					{characterData: true},
					{element: 'ba-option'}
				],
				rootNode: this._element
			}
		);
	}
}

export default BaSelect;
