import { BHR_LS_I18N_PREFIX } from './get_i18n'

export const cleanStaleVersions = () => {
	Object.entries(window.localStorage)
		.filter(([key]) => key.includes(BHR_LS_I18N_PREFIX))
		.forEach(([key, setting]) => {
			if (key === `${ BHR_LS_I18N_PREFIX }version`) {
				window.localStorage.removeItem(key);
			} else if (setting) {
				const resolvedSetting = JSON.parse(setting);
				const hash = resolvedSetting.translation?.version__hash;
				const lang = key.replace(BHR_LS_I18N_PREFIX, '');
				const fileName = window.Res.get(`js/locale/js-messages-${ lang }`);

				if (fileName && fileName.indexOf(hash) === -1) {
					window.localStorage.removeItem(key);
				}
			}
		});
};
