import {
	camelCase,
	kebabCase,
} from 'lodash';
import {
	createLogger,
} from '@utils/dev-logger';

const typeConverters = {
	'boolean': (value, name) => value === '' || value === 'true' || value === name,
	'number': value => Number(value),
	'string': value => value,
	'json': value => JSON.parse(value || 'null'),
};

/**
 * Converts DOM attributes into an settings element based on types associated
 * @param  {object} attributes NamedNodeMap or Array of DOM attributes
 * @param  {object} typeMap    Map of setting names to their types
 * @return {object}            Object representation of settings
 */
const settingsFromAttributes = (attributes, typeMap) => {
	if (typeof attributes !== 'object') {
		throw new Error(`Expected first argument "attributes" to be of type Object but got ${ typeof typeMap }.`);
	}

	if (typeof typeMap !== 'object') {
		throw new Error(`Expected second argument "typeMap" to be of type Object but got ${ typeof typeMap }.`);
	}

	attributes = Array.isArray(attributes) ? attributes : Array.from(attributes);

	return attributes.reduce((memo, attribute) => {
		const optionName = camelCase(attribute.name);
		const type = typeMap[optionName];
		if (type) {
			memo[optionName] = typeConverters[type](attribute.value, attribute.name);
		}
		return memo;
	}, {});
};

/**
 * Returns an array of attribute names obtained from each setting's key
 * @param  {object} settings Map of setting names to values
 * @return {object}         Array of attribute names
 */
const attributeNamesFromSettings = (settings) => {
	return Object.keys(settings).map(kebabCase);
}
;

const elementsNameMap = {
	option: 'BA-OPTION',
	group: 'BA-OPTION-GROUP',
	toggleContent: 'BA-TOGGLE-CONTENT',
	icon: 'BA-ICON',
	fileIcon: 'BA-FILE-ICON',
};
export { elementsNameMap };

const attributesMap = (attribute) => {
	switch (attribute) {
		case 'selected':
			return 'isSelected';
		case 'disabled':
			return 'isDisabled';
		case 'class':
			return 'className';
		case 'for':
			return 'htmlFor';
		case 'action-only':
			return 'isActionOnly';
		default:
			return camelCase(attribute);
	}
};

export function fromElementToItemMap(option) {
	const { attributes } = option;
	return [...attributes].reduce((memo, attribute) => {
		if (attribute.name.includes('button-')) {
			const currentButton = memo.buttonSettings || {};
			let value = attribute.value;

			value = value === 'true' || value === 'disabled' ? true : value;
			value = value === '' || value === 'false' ? false : value;
			memo.buttonSettings = {...currentButton, [camelCase(attribute.name.replace('button-', ''))]: value};
			memo.ButtonProps = {...currentButton, [camelCase(attribute.name.replace('button-', ''))]: value};
			return memo;
		}
		if (attribute.name.includes('placement-')) {
			const placement = memo.placement || {};

			memo.placement = {...placement, [attribute.name.replace('placement-', '')]: attribute.value};
			return memo;
		}

		const name = attributesMap(attribute.name);
		let value = attribute.value;

		// convert empty value to boolean if attribute name isn't 'value'
		value = (name !== 'value' && value === '') || value;
		// convert 'true' or 'disabled' to boolean
		value = ['true', 'disabled'].includes(value) || value;
		// convert 'false' to boolean
		value = (value !== 'false') && value;

		if (typeof value === 'string' && value.charAt(0) === '{' && value.charAt(value.length - 1) === '}') {
			try {
				value = JSON.parse(value);
			} catch (e) {
				const logger = createLogger('fromElementToItemMap util');
				logger.warn(`Couldn't parse JSON-like attribute value: ${ value }`);
			}
		}

		memo[name] = value;

		return memo;
	}, {});
}

function convertOption(option) {
	const _itemAttributeMap = fromElementToItemMap(option);
	const temporaryContainer = document.createElement('div');

	let item = {
		text: option.innerText.trim(),
		..._itemAttributeMap,
		type: 'single',
		render: () => {
			if (
				!option.childElementCount ||
				_itemAttributeMap.note ||
				_itemAttributeMap.text
			) {
				// default to using the built-in option content from fabric
				return;
			}

			const { group } = elementsNameMap;
			const content = [...option.childNodes].filter(node => node.nodeName !== group).map((node) => {
				if (node.outerHTML) {
					return node.outerHTML;
				}
				if (node.nodeValue) {
					temporaryContainer.textContent = node.nodeValue;
					return temporaryContainer.innerHTML;
				}
				return node;
			}).join(' ');

			return (
				<div dangerouslySetInnerHTML={ { __html: content } } />
			);
		},
		value: _itemAttributeMap.hasOwnProperty('value') ? _itemAttributeMap.value : option.innerText.trim(),
		_elem: option
	};

	if (option.childElementCount > 0) {
		item.items = convertToList(option.childNodes, item.value);
	}

	return item;
}

function convertGroup(child) {
	const groupAttributeMap = fromElementToItemMap(child);
	return {
		...groupAttributeMap,
		type: 'group',
		items: convertToList(child.childNodes),
		text: groupAttributeMap.label
	};
}

export function convertToList(childNodes) {

	const {
		option,
		group
	} = elementsNameMap;

	return [...childNodes].reduce((list, child) => {
		if (
			child &&
			child.tagName &&
			!child.hasAttribute('hidden')
		) {
			const tagName = child.tagName;
			const _newList = [...list];

			switch (tagName) {
				case option:
					_newList.push(convertOption(child));
					break;
				case group:
					_newList.push(convertGroup(child));
					break;
			}
			return _newList;
		}
		return list;
	}, []);
}

export {
	attributeNamesFromSettings,
	settingsFromAttributes
};
