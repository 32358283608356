import { Component } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import {
	isFunction,
} from 'lodash';
import { Select } from '@fabric/select';
import {
	fromElementToItemMap
} from '../util';
import {
	objectMapUtil,
	placementOldToNew,
	convertNewItemToOld
} from 'shared/utils';
import {
	renderItems
} from 'shared/options';

export default class SelectWrapper extends Component {
	constructor({ element }) {
		super(...arguments);

		if (!element.hasOwnProperty('value')) {
			Object.defineProperty(
				element,
				'value',
				{
					get: () => {
						const {
							canSelectMultiple,
						} = this._setupProps();
						const selectedValues = this._configSelectedValues();

						if (!canSelectMultiple) {
							return selectedValues[0];
						}

						return selectedValues;
					},
					set: (selectedValues) => {
						if (!Array.isArray(selectedValues)) {
							selectedValues = [selectedValues];
						}

						const {
							element,
						} = this.props;

						Array.from(element.querySelectorAll('ba-option'))
							.forEach((opt) => {
								opt.selected = selectedValues.includes(opt.value);
							});
					},
				}
			);
		}
	}

	_handleSelect = (value, selectedValues) => {
		const { items, onSelect } = this.props;
		const convertedProps = this._setupProps();
		const selectedOptions = items.filter((item) => {
			return selectedValues.includes(item.value);
		}).map((item) => {
			return {
				...item,
				textContent: item.text
			};
		});

		onSelect(selectedOptions, value, selectedValues);

		if (convertedProps.stayOpen) {
			return new Promise(() => {});
		}
	};
	_handleClear = () => {
		const { onSelect } = this.props;

		onSelect([], null, []);
	};
	_configSelectedValues = () => {
		const { items } = this.props;

		if (items) {
			const _selectedValues = [];
			const getSelectedValues = (item) => {
				if (item.isSelected) {
					_selectedValues.push(item.value);
				}
				if (item.items && item.items.length > 0) {
					item.items.forEach(getSelectedValues);
				}
			};
			items.forEach(getSelectedValues);
			return _selectedValues;
		}
		return [];
	}
	_setupProps = () => {
		const { element: {
			attributes
		} } = this.props;
		return objectMapUtil(fromElementToItemMap({ attributes }), {
			disabled: 'isDisabled',
			multiselect: 'canSelectMultiple',
			listPosition: {
				name: 'placement',
				convert: value => placementOldToNew(value)
			},
			notClearable: {
				name: 'isClearable',
				convert: value => !value
			},
			searchThreshold: {
				name: 'searchThreshold',
				convert: value => Number(value)
			},
			canAdd: {
				name: 'onAdd',
				convert: () => {
					return () => {
						const { element } = this.props;
						let resolve;
						let reject;
						const _returnPromise = new Promise(function baSelectOnAdd(_resolve, _reject) {
							resolve = _resolve;
							reject = _reject;
						});

						element.dispatchEvent(
							new CustomEvent('ba:selectAddItem', {
								bubbles: true,
								detail: {
									resolve,
									reject
								}
							})
						);
						return _returnPromise.then((items = []) => {
							const convertedItems = items.map(convertNewItemToOld);
							const renderedItems = renderItems(convertedItems);
							const staticMarkup = renderToStaticMarkup(renderedItems);
							const range = document.createRange();
							const fragment = range.createContextualFragment(staticMarkup);

							element.appendChild(fragment);

							items.forEach((item) => {
								this._handleSelect(item.value, [item.value]);
							});
						});
					};
				}
			}
		});
	}
	_isValid = (isValid) => {
		const { element } = this.props;
		const hiddenSelect = element.querySelector('select');

		hiddenSelect.dispatchEvent(new CustomEvent('validation', {
			bubbles: true,
			cancelable: true,
			detail: {
				isValid
			}
		}));
		this.setState({
			isValid
		});
	}
	state = {
		isValid: true
	};

	componentDidUpdate() {
		const { element } = this.props;
		const { isValid } = this.state;

		if (element.attributes.error && isValid) {
			this._isValid(false);
		}
		if (!element.attributes.error && !isValid) {
			this._isValid(true);
		}
	}
	render() {
		const { element, items } = this.props;
		const convertedProps = this._setupProps();
		const selectedValues = this._configSelectedValues();
		const toggleContent = element.querySelector('ba-toggle-content');

		if (
			toggleContent instanceof HTMLElement &&
			!convertedProps.renderToggleContent
		) {
			convertedProps.renderToggleContent = () => (
				<div dangerouslySetInnerHTML={ { __html: toggleContent.innerHTML } } />
			);
		}

		return (
			<Select
				items={ items }
				onClear={ this._handleClear }
				onDeselect={ this._handleSelect }
				onSelect={ this._handleSelect }
				renderOptionContent={ item => (isFunction(item.render) ? item.render() : null) }
				selectedValues={ selectedValues }
				{ ...convertedProps }
				id={element.attributes.component_id?.value || element.id}
			/>
		);
	}
}
