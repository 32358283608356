import 'public-path';
import 'core-js/stable';

import './datadog';

// eslint-disable-next-line no-restricted-imports
import '../res';

// eslint-disable-next-line no-restricted-imports
import 'jquery.lib';

import 'rollbar/dist/plugins/jquery.min';

import 'jquery.multiselect.lib';
import 'jquery.maskedinput.lib';

import 'lodash/index';

import ajax from '@utils/ajax';

Object.defineProperty(window, 'Ajax', {
	writable: false,
	value: ajax
});

import 'btn.config';

import './_plugins';

import './_i18n';

import 'moment.lib';

import { Table } from '@bamboohr/fabric';

import { attachTokens, updateInput } from 'csrf.util';
window.attachCSRFTokens = attachTokens;
window.attachCSRFInput = updateInput;

import * as Utils from 'BambooHR.util';
window.BambooHR = window.BambooHR || {};
window.BambooHR.Utils = Utils;
Utils.Mediator.installTo(window.BambooHR);

import * as FeatureToggle from 'FeatureToggle.util';
window.BambooHR.FeatureToggle = FeatureToggle;

import 'custom-elements.comp';

import 'Alerts.mod';

import 'jquery.ajax-setup.lib';

import microTemplate from 'MicroTemplate.mod';
window.microTemplate = microTemplate;

import 'comp.deco';
import 'ctrl.deco';

if (Utils.canUseLocalStorage()) {
	const isDev = window.BambooHR.env.dev;

	if (window.localStorage.getItem('BHR_DEV_LOGGER') !== 'true' && isDev) {
		window.localStorage.setItem('BHR_DEV_LOGGER', 'true');
	}
	if (!isDev) {
		window.localStorage.removeItem('BHR_DEV_LOGGER');
	}
}

Table.tooltipAdapter = {
	show(element, title, content) {
		if (content) {
			// check for content because some rows may have empty action placeholders
			window.BambooHR.Tooltip.createStandard(element, {
				title,
				content,
				onHide(tooltip) {
					tooltip.suppress();
					tooltip.balloonElement.style.pointerEvents = 'none';
					setTimeout(() => {
						tooltip.destroy();
					}, tooltip.settings.fadeOut);
				},
			});
		}
	}
};

import { setNumberRegex, setValidateCallback } from '@utils/validation';
if (window.GLOBAL_NUMBER_REGEX) {
	setNumberRegex(window.GLOBAL_NUMBER_REGEX);
}
setValidateCallback(() => {
	setMessage($.__('Whoops... No worries. Please fix any missing or incorrect information and try again.'), 'error');
});
