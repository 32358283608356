import {
	getParameterByName
} from '@utils/url';
import { htmlentities } from 'String.util';

const languageMap = {
	es_AR: 'es',
	de_DE: 'de',
	ja_JP: 'ja',
};

export const defaultLanguage = 'en';

export const getCurrentLanguage = () => {
	try {
		const rawLang = getParameterByName('lang') || document.documentElement.dataset.locale;
		const lang = htmlentities(rawLang);
		if (!lang || lang === 'undefined') { return defaultLanguage; }
		return (languageMap[lang] || lang).replace('_', '-');
	} catch (err) {
		return defaultLanguage;
	}
};
